import React from 'react'
import clsx from 'clsx'
import store from 'store'
import {
  ChevronLeft,
  ChevronRight,
  People,
  History,
  Settings,
  CloudUpload,
  Update,
  AccountCircle,
  LocationOn,
  LocalAtm,
  HomeWork,
  Print,
  Casino,
  Museum,
} from '@material-ui/icons'
import { Drawer, Divider, IconButton, List } from '@material-ui/core'
import Item from '../item'

export default props => {
  const { classes, theme, open, direction, handleDrawerClose } = props
  const user = store.get('user')
  const admin = user && user.role === 'admin'

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      open={open}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={handleDrawerClose} className={classes.listIcon}>
          {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </div>
      <Divider />
      <List>
        {admin && (
          <>
            <Item classes={classes} label="ລູກຄ້າ" icon={<People />} click={direction} />
            <Divider className={classes.divider} />
          </>
        )}
        <Item classes={classes} label="ຈັດການສິນຄ້າ (ຈີນ)" icon={<Casino />} click={direction} />
        <Item classes={classes} label="ປະຫວັດສິນຄ້າ (ຈີນ)" icon={<Update />} click={direction} />
        {!admin && (
          <Item
            classes={classes}
            label="ນຳເຂົ້າຂໍ້ມູນ (ຈີນ)"
            icon={<CloudUpload />}
            click={direction}
          />
        )}
        <Divider className={classes.divider} />
        <Item classes={classes} label="ຈັດການສິນຄ້າ (ໄທ)" icon={<Museum />} click={direction} />
        <Item classes={classes} label="ປະຫວັດສິນຄ້າ (ໄທ)" icon={<History />} click={direction} />
        {!admin && (
          <Item
            classes={classes}
            label="ນຳເຂົ້າຂໍ້ມູນ (ໄທ)"
            icon={<CloudUpload />}
            click={direction}
          />
        )}
        <Divider className={classes.divider} />

        <Item classes={classes} label="ພິມບິນຂົນສົ່ງ" icon={<Print />} click={direction} />
        <Item classes={classes} label="ຕັ້ງຄ່າແຈ້ງເຕືອນ" icon={<Settings />} click={direction} />
        {admin && (
          <>
            <Item classes={classes} label="ຕັ້ງຄ່າທີ່ຢູ່" icon={<LocationOn />} click={direction} />
            <Item
              classes={classes}
              label="ຕັ້ງຄ່າຄິດໄລ່ຄ່າບໍລິການ"
              icon={<LocalAtm />}
              click={direction}
            />
            <Divider className={classes.divider} />
            <Item classes={classes} label="ພະນັກງານ" icon={<AccountCircle />} click={direction} />
            <Item classes={classes} label="ສາງ" icon={<HomeWork />} click={direction} />
          </>
        )}
      </List>
      <Divider />
    </Drawer>
  )
}
