import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { map } from 'lodash'
import store from 'store'
import moment from 'moment'
import queryString from 'query-string'
import { TableProduct } from '../../components/table'
import { useLoading, useDialog } from '../../components'
import http from '../../service/http'
import Modal from '../../components/editChina'

const headCells = [
  { id: 'index', numeric: false, disablePadding: true, label: 'ລຳດັບ' },
  { id: 'time', numeric: true, disablePadding: false, label: 'ວັນທີ' },
  { id: 'user_tel', numeric: true, disablePadding: false, label: 'ເບີໂທ' },
  { id: 'user_name', numeric: true, disablePadding: false, label: 'ຊື່' },
  { id: 'code', numeric: true, disablePadding: false, label: 'ລະຫັດ' },
  { id: 'weight', numeric: true, disablePadding: false, label: 'ນ້ຳໜັກ' },
  { id: 'volume', numeric: true, disablePadding: false, label: 'ບໍລິມາດ' },
  { id: 'cost', numeric: true, disablePadding: false, label: 'ລາຄາ (KIP)' },
  { id: 'warehouse_name', numeric: true, disablePadding: false, label: 'ສາງ' },
  { id: 'action', numeric: true, disablePadding: false, label: 'ຈັດການ' },
]

export default () => {
  const userStore = store.get('user')
  const history = useHistory()

  const [rows, setRows] = useState([])
  const [Loading, withLoading] = useLoading(false)
  const [Dialog, showWithMessage, showWithConfig] = useDialog({})
  const [actionEdit, setActionEdit] = useState(false) // false = create and true = edit
  const [show, setShow] = useState(false)
  const [total, setTotal] = useState(0)
  const [initialValue, setInitialValue] = useState({})
  const [selected, setSelected] = useState([])
  const [sum, setSum] = useState([])
  const [bath, setBath] = useState([])

  const getListProduct = async (argOffset, argLimit, argSort, argSearch, argFilter) => {
    try {
      const { search } = history.location
      const queryPath = queryString.parse(search)
      const from = argFilter && argFilter.from && new Date(argFilter.from)
      const to = argFilter && argFilter.to && new Date(argFilter.to)
      const timezone = 7
      from && from.setTime(from.getTime() - timezone * 60 * 60 * 1000)
      to && to.setTime(to.getTime() - timezone * 60 * 60 * 1000)
      const payload = {
        offset: argOffset ?? (queryPath.offset || 0),
        limit: argLimit ?? (queryPath.limit || 25),
        search: argSearch,
        sortBy: (argSort && argSort.sortBy) || queryPath.sortBy || 'time',
        sortType: (argSort && argSort.sortType) || queryPath.sortType || 'desc',
        status: 'waiting',
        warehouse_id: userStore?.warehouse_id ? userStore.warehouse_id : undefined,
        type: 'cn',
        range: argFilter && argFilter.range,
        from: from && from.toISOString(),
        to: to && to.toISOString(),
        filterType: 'time',
      }
      const query = queryString.stringify(payload)
      history.replace(`/product-china?${query}`)
      const response = await withLoading(() => http.get(`product/list?${query}`))
      if (response.status !== 'success') {
        return showWithMessage(response.message)
      }

      const result = map(response.data.data, (item, index) => ({
        ...item,
        index: index + (argOffset || 0) + 1,
      }))
      setTotal(response.data.total)
      setRows(result)
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }

  const handleProcess = async selected => {
    try {
      const { status, message } = await http.put('product/update', {
        ids: selected,
      })
      if (status !== 'success') {
        showWithMessage(message)
      } else {
        showWithConfig({
          message: ' ​ຂໍ້ມູນທີ່ຖືກປັບປຸງສໍາ​ເລັດ',
          title: 'ລາຍການສິນຄ້າ',
          type: 'success',
        })
        getListProduct()
      }
      setSelected([])
      setSum([])
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }

  const openConfirm = selected => {
    showWithConfig({ message: 'ທ່ານແນ່ໃຈບໍ່ທີ່ຈະດຳເນີນການ ?', title: 'ຢືນຢັນ' }, () =>
      handleProcess(selected),
    )
  }

  const addProduct = () => {
    setInitialValue({})
    setActionEdit(false)
    setShow(true)
  }

  const handleSubmit = async payload => {
    try {
      let result
      const data = {
        user_name: payload.user_name.trim(),
        user_tel: payload.user_tel.trim(),
        code: payload.code.trim(),
        weight: payload.weight,
        volume: payload.volume,
        cost: payload.cost,
        type: 'cn',
        warehouse_name: payload.warehouse_name,
        warehouse_id: userStore?.warehouse_id,
      }
      if (actionEdit) {
        result = await withLoading(() => http.put(`product/edit/${payload._id}`, data))
      } else {
        data.time = moment.utc().format()
        result = await withLoading(() => http.post('product/create', data))
      }

      if (result.status === 'success') {
        showWithConfig({
          message: ' ​ຂໍ້ມູນບັນທຶກສໍາ​ເລັດແລ້ວ',
          title: 'ລາຍການສິນຄ້າ',
          type: 'success',
        })
        getListProduct()
      } else {
        showWithConfig({
          message: ` ຂໍ້ມູນບັນທຶກບໍ່ສໍາ​ເລັດ ${result.message}`,
          title: 'ຂໍ້ມູນຜິດພາດ',
          type: 'error',
        })
      }
      setShow(false)
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }

  // const handleDelete = async selected => {
  //   try {
  //     const { status, message } = await http.post('product/delete', {
  //       ids: selected,
  //     })
  //     if (status !== 'success') {
  //       return showWithMessage(message)
  //     }
  //     showWithConfig({
  //       message: ' ​ຂໍ້ມູນທີ່ຖືກປັບປຸງສໍາ​ເລັດ',
  //       title: 'ລາຍການສິນຄ້າ',
  //       type: 'success',
  //     })
  //     setSelected([])
  //     setSum([])
  //     return getListProduct()
  //   } catch (error) {
  //     console.log(error)
  //     showWithMessage(error.message)
  //   }
  // }

  // const openConfirmDelete = selected => {
  //   showWithConfig({ message: 'ທ່ານແນ່ໃຈບໍ່ທີ່ຈະດຳເນີນການ ?', title: 'ຢືນຢັນ' }, () =>
  //     handleDelete(selected),
  //   )
  // }

  const editProduct = payload => {
    const edit = {
      ...payload,
      weight: payload.weight || 0,
      volume: payload.volume || 0,
    }
    setInitialValue(edit)
    setActionEdit(true)
    setShow(true)
  }

  useEffect(() => {
    getListProduct()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <TableProduct
        name="ລາຍການສິນຄ້າ (ຈີນ)"
        rows={rows}
        total={total}
        headCells={headCells}
        openConfirm={openConfirm}
        getListProduct={getListProduct}
        addProduct={addProduct}
        editProduct={editProduct}
        // openConfirmDelete={openConfirmDelete}
        setSelected={setSelected}
        selected={selected}
        setSum={setSum}
        sum={sum}
        setBath={setBath}
        bath={bath}
      />
      <Loading />
      <Dialog />
      <Modal
        show={show}
        handleClose={() => setShow(false)}
        initialValues={initialValue}
        actionEdit={actionEdit}
        handleSave={handleSubmit}
      />
    </>
  )
}
